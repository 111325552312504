import LogoWhite from '@assets/logos/logo.png';
import './style.css';
import Buttom from '@components/buttons';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Index = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const BinanceIcon = () => {
    return (
      <img src={require('../assets/images/bnb.png')} style={{ width: 35 }} />
    );
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const referCode = queryParams.get('refercode');

    if (referCode) {
      localStorage.setItem('refercode', referCode);
      console.log('Refercode saved:', referCode);
    } else {
      localStorage.removeItem('refercode');
    }
  }, [location]);

  const [isOpenMenu, setisOpenMenu] = useState(false);
  const openNav = () => {
    setisOpenMenu(!isOpenMenu);
  };

  useEffect(() => {
    // Agregar clase al body al entrar en el componente
    document.body.classList.add('overflow-scroll');

    // Limpiar la clase al salir del componente
    return () => {
      document.body.classList.remove('overflow-scroll');
    };
  }, []);

  return (
    <div className="launch-container" id="dmo-token">
      <nav id="main-nav">
        <div className="content-nav">
          <div>
            <Link to="/">
              <img src={LogoWhite} alt="MiningOptions" className="logoLaunch" />
            </Link>
          </div>
          <div>
            <ul>
              <li className="activenav">
                <Link to="/dmo-token">DMO Token</Link>
              </li>
              <li>
                <a
                  href="https://miningoptions.net/MININGOPTIONS.pdf"
                  target="_blank"
                  translate="no"
                >
                  White Paper
                </a>
              </li>
              <li>
                <Link to="/#faq">FAQ</Link>
              </li>

              <li>
                <Link to="/contact-us">Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="social-network">
            <a href="https://t.me/mining_options" target="_blank">
              <img
                src={require('../assets/images/telegram2.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://x.com/mining_options?s=11&t=P5Q0Gh941v-xBEugpAY3lg"
              target="_blank"
            >
              <img
                src={require('../assets/images/twitter-alt-circle.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://www.instagram.com/mining_options?igsh=MzRlODBiNWFlZA=="
              target="_blank"
            >
              <img
                src={require('../assets/images/instagram.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://youtube.com/@mining_options?si=y6RqQmusDDybkmfc"
              target="_blank"
            >
              <img
                src={require('../assets/images/youtube.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://www.binance.com/en/square/profile/mining_options"
              target="_blank"
            >
              <BinanceIcon />
            </a>
          </div>
          <div
            className="social-network"
            style={{
              width: 120,
              margin: '0 auto',
            }}
          ></div>
        </div>
        <div className="icon-bars-menu-launch" onClick={openNav}>
          <i className="fas fa-bars"></i>
        </div>
      </nav>
      <div
        className={`mobile-nav-menu-content ${isOpenMenu ? 'active' : ''}`}
        onClick={openNav}
      >
        <div className="mobile-nav-menu">
          <div className="items-menu">
            <div className="section-item-menu">
              <ul>
                <li className={`left-menu-item`}>
                  <Link to="/dmo-token">DMO Token</Link>
                </li>
                <li className={`left-menu-item`}>
                  <a
                    href="https://miningoptions.net/MININGOPTIONS.pdf"
                    target="_blank"
                    translate="no"
                  >
                    White Paper
                  </a>
                </li>

                <li className={`left-menu-item`}>
                  <Link to="/#faq">FAQ</Link>
                </li>

                <li className={`left-menu-item`}>
                  <Link to="/contact-us">Contact us</Link>
                </li>
              </ul>
              <div
                style={{
                  width: 120,
                  margin: '0 auto',
                }}
              >
                <Buttom
                  size="lg"
                  label="Start Now"
                  onClick={() => {
                    navigate('/sing-in');
                  }}
                />
              </div>

              <div className="social-network" style={{ margin: '30px auto' }}>
                <a href="https://t.me/mining_options" target="_blank">
                  <img
                    src={require('../assets/images/telegram2.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://x.com/mining_options?s=11&t=P5Q0Gh941v-xBEugpAY3lg"
                  target="_blank"
                >
                  <img
                    src={require('../assets/images/twitter-alt-circle.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/mining_options?igsh=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  <img
                    src={require('../assets/images/instagram.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://youtube.com/@mining_options?si=y6RqQmusDDybkmfc"
                  target="_blank"
                >
                  <img
                    src={require('../assets/images/youtube.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://www.binance.com/en/square/profile/mining_options"
                  target="_blank"
                >
                  <BinanceIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <section className="padding-inline">
        <div id="usability-dmo-token">
          <div className="item-usability left">
            <div className="item">
              <h3>NO FEES</h3>
            </div>
            <div className="item">
              <h3>DESCENTRALIZED</h3>
            </div>
          </div>
          <div id="image-phone">
            <img src={require('./assets/trade.png')} alt="" />
          </div>
          <div className="item-usability right">
            <div className="item">
              <h3>INSTANT ORDER EXECUTION</h3>
            </div>
            <div className="item">
              <h3>NO IMPACT ON PRICE</h3>
            </div>
          </div>
        </div>
      </section>
      <section id="about" className="content padding-inline">
        <h1>DESCENTRALIZED MARGIN OPTIONS</h1>
        <div className="card-home">
          <p>
            Margin trading on the web 3 has never been easier, faster and safer,
            with full control of your funds and easy access to the most powerful
            margin trading tools available such as limit, market, stop loss and
            stop profit orders. No commissions and no impact on price connected
            to the exchanges with the largest spot volume on the market with
            leverage of up to 50x. Position openings and closings are carried
            out immediately and according to the values ​​delivered, achieving
            the same performance as CEX but maintaining custody of your funds
            and without paying high commissions. In addition, you will have
            permanent access to liquidity and volume data so that authenticity
            is always verified and regulated.
          </p>
        </div>
      </section>
      <br />
      <section id="core-features" className="content padding-inline">
        <center>
          <h1>CORE FEATURES</h1>
        </center>
        <br />

        <div className="flex-row">
          <div className="card-home">
            <h2>DMO Token</h2>
            <p>
              Our token has a fixed supply and represents a value within our DEX
              ecosystem, which has a constant repurchase system equivalent to
              10% of the income obtained each quarter. These tokens are
              distributed for staking rewards, guaranteeing participants
              constant revaluation and liquidity.
            </p>
          </div>
          <div className="card-home">
            <h2>Liquidity provider</h2>
            <p>
              Transactions are executed and processed automatically without any
              extra commission to the network cost, for the immediate
              availability of funds in each transaction we use a public
              monitoring liquidity contract authorized and regulated with the
              corresponding brokerage licenses.
            </p>
          </div>
        </div>
        <br />
        <br />

        <div className="content">
          <div className="flex-row">
            <div className="card-home">
              <p className="text-center">
                Earn fixed stake earnings regardless of your participation level
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Trade on margin without any commission on web 3
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Telegram mini apps support on ton network
              </p>
            </div>
          </div>
        </div>

        <br />

        <div className="content">
          <div className="flex-row">
            <div className="card-home">
              <p className="text-center">
                Real spot prices from major exchanges without any price
                manipulation
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Algorithm in Inter-Blockchain Communication (IBC) Protocol
              </p>
            </div>
            <div className="card-home">
              <p className="text-center">
                Intuitive interface and the most popular options for margin
                trading
              </p>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br /> <br />
      <br />
      <section className="content padding-inline" id="start-you-crypto">
        <br />
        <div className="card-home overflow-hidden">
          <div className="flex-row">
            <div className="item-start-your flex aling-items-center">
              <div style={{ width: '70%', margin: '0 auto' }}>
                <h2 className="text-center">
                  Have fun accumulating $DMO tokens and get great benefits at
                  launch
                </h2>
                <br />
                <div style={{ width: '70%', margin: '0 auto' }}>
                  <Buttom
                    size="lg"
                    label="Register Now"
                    onClick={() => {
                      navigate('/sing-in');
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="item-start-your">
              <div className="flex-row justify-content-center">
                <img
                  src={require('./assets/AIRDROP.png')}
                  alt="AIRDROP Mining Options"
                />
              </div>
            </div>
          </div>
        </div>
        <br />
      </section>
      <br />
      <br />
      <section className="content padding-inline">
        <div id="footer-btns">
          <div>
            <Link to="/">Mining Options</Link>

            <div>
              <a
                href="https://miningoptions.net/MININGOPTIONS.pdf"
                target="_blank"
                translate="no"
              >
                White Paper
              </a>
            </div>
            <Link to="/team">Team</Link>
            <Link to="/contact-us">Contact Us</Link>
          </div>
        </div>
      </section>
      <br />
      <br />
      <footer>
        <center>
          <p style={{ fontSize: 14 }}>
            &copy; 2024 - 2025 MiningOptions.net. All Rights Reserved.
          </p>
        </center>
      </footer>
      <br />
      <br />
    </div>
  );
};

export default Index;
