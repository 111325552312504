import LogoWhite from '@assets/logos/logo.png';
import './style.css';
import Buttom from '@components/buttons';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import emailjs from 'emailjs-com';

const Index = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const BinanceIcon = () => {
    return (
      <img src={require('../assets/images/bnb.png')} style={{ width: 35 }} />
    );
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const referCode = queryParams.get('refercode');

    if (referCode) {
      localStorage.setItem('refercode', referCode);
      console.log('Refercode saved:', referCode);
    } else {
      localStorage.removeItem('refercode');
    }
  }, [location]);

  const [isOpenMenu, setisOpenMenu] = useState(false);
  const openNav = () => {
    setisOpenMenu(!isOpenMenu);
  };

  useEffect(() => {
    // Agregar clase al body al entrar en el componente
    document.body.classList.add('overflow-scroll');

    // Limpiar la clase al salir del componente
    return () => {
      document.body.classList.remove('overflow-scroll');
    };
  }, []);

  const [userName, setUserName] = useState('');
  const [message, setMessage] = useState('');
  const sendEmail = () => {
    emailjs.init('ImxcGdqnpxzfABnt9'); // Reemplaza con tu User ID de EmailJS

    if (userName === '') {
      alert('Personal email is required');
      return false;
    }
    if (message === '') {
      alert('Message is required');
      return false;
    }
    const data = {
      to_name: 'Mining',
      from_name: userName,
      message: message,
    };
    emailjs
      .send('service_4crg4vq', 'template_6tu09k9', data)
      .then((response) => {
        console.log('Correo enviado exitosamente:', response);
        alert('Successful');
      })
      .catch((error) => {
        console.error('Error al enviar el correo:', error);
        alert('Hubo un error al enviar el correo');
      });
  };

  const handleInputChange = (event: any) => {
    setUserName(event.target.value);
  };
  const handleTextAreaChange = (event: any) => {
    setMessage(event.target.value);
  };

  return (
    <div className="launch-container">
      <nav id="main-nav">
        <div className="content-nav">
          <Link to="/">
            <img src={LogoWhite} alt="MiningOptions" className="logoLaunch" />
          </Link>
          <div>
            <ul>
              {/* <li>
                <Link to="/dmo-token">DMO Token</Link>
              </li> */}
              <li>
                <a
                  href="https://miningoptions.net/MININGOPTIONS.pdf"
                  target="_blank"
                  translate="no"
                >
                  White Paper
                </a>
              </li>
              <li>
                <Link to="/#faq">FAQ</Link>
              </li>

              <li className="activenav">
                <a href="#">Contact us</a>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="social-network">
            <a href="https://t.me/mining_options" target="_blank">
              <img
                src={require('../assets/images/telegram2.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://x.com/mining_options?s=11&t=P5Q0Gh941v-xBEugpAY3lg"
              target="_blank"
            >
              <img
                src={require('../assets/images/twitter-alt-circle.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://www.instagram.com/mining_options?igsh=MzRlODBiNWFlZA=="
              target="_blank"
            >
              <img
                src={require('../assets/images/instagram.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://youtube.com/@mining_options?si=y6RqQmusDDybkmfc"
              target="_blank"
            >
              <img
                src={require('../assets/images/youtube.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://www.binance.com/en/square/profile/mining_options"
              target="_blank"
            >
              <BinanceIcon />
            </a>
          </div>
          <div
            className="social-network"
            style={{
              width: 120,
              margin: '0 auto',
            }}
          ></div>
        </div>
        <div className="icon-bars-menu-launch" onClick={openNav}>
          <i className="fas fa-bars"></i>
        </div>
      </nav>
      <div
        className={`mobile-nav-menu-content ${isOpenMenu ? 'active' : ''}`}
        onClick={openNav}
      >
        <div className="mobile-nav-menu">
          <div className="items-menu">
            <div className="section-item-menu">
              <ul>
                {/* <li className={`left-menu-item`}>
                  <Link to="/dmo-token">DMO Token</Link>
                </li> */}
                <li className={`left-menu-item`}>
                  <a
                    href="https://miningoptions.net/MININGOPTIONS.pdf"
                    target="_blank"
                    translate="no"
                  >
                    White Paper
                  </a>
                </li>

                <li className={`left-menu-item`}>
                  <Link to="/#faq">FAQ</Link>
                </li>

                <li className={`left-menu-item`}>
                  <Link to="/contact-us">Contact us</Link>
                </li>
              </ul>
              <div
                style={{
                  width: 120,
                  margin: '0 auto',
                }}
              >
                <Buttom
                  size="lg"
                  label="Start Now"
                  onClick={() => {
                    navigate('/sing-in');
                  }}
                />
              </div>

              <div className="social-network" style={{ margin: '30px auto' }}>
                <a href="https://t.me/mining_options" target="_blank">
                  <img
                    src={require('../assets/images/telegram2.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://x.com/mining_options?s=11&t=P5Q0Gh941v-xBEugpAY3lg"
                  target="_blank"
                >
                  <img
                    src={require('../assets/images/twitter-alt-circle.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/mining_options?igsh=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  <img
                    src={require('../assets/images/instagram.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://youtube.com/@mining_options?si=y6RqQmusDDybkmfc"
                  target="_blank"
                >
                  <img
                    src={require('../assets/images/youtube.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://www.binance.com/en/square/profile/mining_options"
                  target="_blank"
                >
                  <BinanceIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />

      <section className="content padding-inline">
        <h1>CONTACT US</h1>
        <br />
        <div id="form-contact">
          <div className="flex-row">
            <input
              type="text"
              placeholder="Personal email (Required)"
              value={userName}
              onChange={handleInputChange}
            />
            <input type="text" placeholder="UID or name (Optional)" />
          </div>
          <div>
            <br />
            <textarea
              name=""
              id=""
              value={message}
              onChange={handleTextAreaChange}
            ></textarea>
          </div>
          <br />
          <div id="content-submit" onClick={sendEmail}>
            <div id="btn-submit">Send Message</div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <section className="content padding-inline">
        <h1>OFFICIAL COMMUNITIES</h1>
        <br />
        <div className="flex-row justify-content-center gap10">
          <a
            className="item-telegram"
            target="_blank"
            href="https://t.me/miningoptionscommunity"
          >
            English Telegram Community
            <br />
            <br />
            <div className="icontelegram">
              <i className="fab fa-telegram-plane"></i>
            </div>
          </a>
          {/* <div className="item-telegram" onClick={() => alert('Coming soon')}>
            Spanish Telegram Community
            <br />
            <br />
            <div className="icontelegram">
              <i className="fab fa-telegram-plane"></i>
            </div>
          </div> */}
        </div>
      </section>
      <br />
      <br />
      <br />
      <section className="content padding-inline">
        <h1>FOR CORPORATES REQUERIMENTS</h1>
        <br />
        <div className="flex-row justify-content-center gap10">
          <div className="item-telegram hours">
            <h3>Opening Hours:</h3>
            <p>Monday to Friday 09:00 - 04:00 (GMT-5)</p>
            <h3>+1 (231) 283-4345</h3>
          </div>
        </div>
      </section>
      <br />
      <br />
      <footer>
        <center>
          <p style={{ fontSize: 14 }}>
            &copy; 2024 - 2025 MiningOptions.net. All Rights Reserved.
          </p>
        </center>
      </footer>
      <br />
      <br />
    </div>
  );
};

export default Index;
