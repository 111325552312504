import './style.css';
import { InputText } from '@components/forms';
import Layout from '@components/layout';
import { AppStateType } from '@redux/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useWallet } from '@hooks/useWallet';
import Buttom from '@components/buttons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type typeOptions = 'Select Network' | 'BEP-20';

function App() {
  const { i18n } = useTranslation();
  //@ts-ignore
  const langEng = i18n.language === 'en' ?? false;

  const navigate = useNavigate();
  const { data: balanceWallet, withDraw } = useWallet();
  const [balance, setBalance] = useState<any>('0');
  const [amount, setAmount] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<typeOptions>('BEP-20');

  useEffect(() => {
    setBalance(balanceWallet ? balanceWallet.balance : 0);
  }, [balanceWallet, selectedOption]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleOptionSelect = (option: typeOptions) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
  };

  const setMax = () => {
    setAmount(balance);
  };

  const onTransfer = () => {
    const currentBalance = balance.replace(',', '');
    if (amount === '') {
      alert('Amount is required');
      return false;
    }
    if (address === '') {
      alert('Address is required');
      return false;
    }
    if (parseFloat(amount) < 2) {
      alert('Minimum 2.00 USDT');
      return false;
    }

    if (parseFloat(currentBalance) < parseFloat(amount)) {
      alert('The requested amount is greater than the current balance');
      return false;
    }

    const isConfirmed = window.confirm('Are you sure to perform this action?');

    if (isConfirmed) {
      const data = {
        amount,
        address,
      };
      withDraw(data)
        .then(() => {
          alert('successful operation');
          navigate('/wallet');
        })
        .catch(() => {
          alert('An error occurred');
        });
    } else {
      console.log('Cancelado');
    }
  };

  const handleInputChange = (inputValue: any) => {
    inputValue = inputValue.replace(/[^0-9.]/g, '');

    if (inputValue.split('.').length > 2) {
      return;
    }

    setAmount(inputValue);
  };

  return (
    <Layout
      title={langEng ? 'WITHDRAW' : 'RETIROS'}
      icon={false}
      showBottomNav={true}
      showNavMobile={false}
    >
      <div id="withdraw">
        <div className="content-deposit">
          <div className="dropdown-deposit" onClick={toggleDropdown}>
            <span>{selectedOption}</span>
            <i className="fas fa-chevron-down"></i>
            {isDropdownOpen && (
              <div className="dropdown-menu">
                <div
                  onClick={() => handleOptionSelect('BEP-20')}
                  className="dropdown-item"
                >
                  BEP-20
                </div>
              </div>
            )}
          </div>
          <br />

          <div className="transaction-form">
            <div className="input-group">
              <InputText
                placeholder={
                  langEng
                    ? 'Network fee 1.50 USDT + 3% commission'
                    : 'Tarifa de red 1,50 USD + 3% de comisión'
                }
                size="md"
                label={langEng ? 'Address' : 'Dirección'}
                value={address}
                onChange={setAddress}
              />
            </div>
            <div className="input-group">
              <InputText
                placeholder={langEng ? 'Minimum 2.00 USDT' : 'Mínimo 2,00 USDT'}
                size="md"
                label={langEng ? 'Amount' : 'Monto'}
                value={amount}
                action="MAX"
                onChange={handleInputChange}
                onAction={setMax}
              />
            </div>
            <div className="details">
              <span className="content-available-stake">
                <span>
                  {langEng ? 'Available:' : 'Disponible'}{' '}
                  <b>
                    {balance}
                    USDT
                  </b>
                </span>
              </span>
            </div>

            <center>
              <Buttom
                label={langEng ? 'CONFIRM' : 'CONFIRMAR'}
                size="lg"
                onClick={onTransfer}
              />
            </center>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default App;
