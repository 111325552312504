import Layout from '@components/layout';
import './style.css';
import { useState } from 'react';
import Tabs from '@components/tabs';
import SwipeableComponent from '@components/swipeable';
import { useWallet } from '@hooks/useWallet';
import { Transaction } from 'types/wallet';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  //@ts-ignore
  const langEng = i18n.language === 'en' ?? false;

  const { data, withDrawData } = useWallet();

  const [activeTab, setActiveTab] = useState<number>(0);
  const [activeSwipeView, setActiveSwipeView] = useState<number>(0);

  const EyeBalance = ({ amount }: { amount: string }) => {
    const [hide, setHide] = useState<boolean>(true);

    return (
      <>
        <span onClick={() => setHide(!hide)} className="info-icon">
          {hide ? (
            <i className="fas fa-eye"></i>
          ) : (
            <i className="fas fa-eye-slash"></i>
          )}
        </span>
        {hide ? (
          <div className="balance-amount">{amount} USDT</div>
        ) : (
          <div className="balance-amount">*******</div>
        )}
      </>
    );
  };

  const ListData = ({ data }: { data: Transaction[] }) => {
    const render = data.map((item: any) => {
      return (
        <>
          <div style={{ width: '95%', margin: '0 auto' }}>
            <div className="listItem">
              <div>{item.status === 'done' ? 'Completed' : 'Pending'}</div>
              <div>{`${item.amount} USDT`}</div>
              <div>{item.date}</div>
            </div>
          </div>
        </>
      );
    });

    return (
      <div className="item-deposit">
        <div style={{ width: '95%', margin: '0 auto' }}>
          <div className="listItem head">
            <div>{langEng ? 'Status' : 'Estatus'}</div>
            <div style={{ marginRight: 5 }}>{langEng ? 'Amount' : 'Monto'}</div>
            <div style={{ marginRight: 20 }}>{langEng ? 'Date' : 'Fecha'}</div>
          </div>
        </div>
        <div className="items-transactions">{render}</div>
      </div>
    );
  };

  const ListDataWithDraw = ({ data }: any) => {
    const render = data.map((item: any) => {
      return (
        <>
          <div style={{ width: '95%', margin: '0 auto' }}>
            <div className="listItem">
              <div>{item.status === 'Done' ? 'Completed' : 'Pending'}</div>
              <div>{`${item.amount} USDT`}</div>
              <div>{item.date}</div>
            </div>
          </div>
        </>
      );
    });

    return (
      <div className="item-deposit">
        <div style={{ width: '95%', margin: '0 auto' }}>
          <div className="listItem head">
            <div>{langEng ? 'Status' : 'Estatus'}</div>
            <div style={{ marginRight: 5 }}>{langEng ? 'Amount' : 'Monto'}</div>
            <div style={{ marginRight: 20 }}>{langEng ? 'Date' : 'Fecha'}</div>
          </div>
        </div>
        <div className="items-transactions">{render}</div>
      </div>
    );
  };

  const tabs = [
    {
      title: langEng ? 'Deposits' : 'Depositos',
    },
    {
      title: langEng ? 'Withdraws' : 'Retiros',
    },
  ];

  const handleIndexChange = (index: number) => {
    setActiveTab(index);
    setActiveSwipeView(index);
  };

  const handleIndexChangeTab = (index: number) => {
    setActiveSwipeView(index);
  };

  const components = [
    <ListData data={data ? data.transactions : []} />,
    <ListDataWithDraw data={withDrawData ?? []} />,
  ];

  return (
    <Layout showBottomNav={true} showGoBack={false}>
      <div id="wallet">
        <div className="balance-container">
          <div className="balance-section">
            <span className="balance-label">
              {langEng ? 'Total balance' : 'Saldo total'}
            </span>
            <EyeBalance amount={data ? data.balance : '0.0'} />
          </div>
        </div>

        <div className="wallet-actions">
          <Link to="/deposit" className="item">
            <i className="fas fa-arrow-up"></i>
            <span> {langEng ? 'Deposit' : 'Depositos'}</span>
          </Link>
          <Link to="/withdraw" className="item">
            <i className="fas fa-arrow-down"></i>
            <span>{langEng ? 'Withdraw' : 'Retiros'}</span>
          </Link>
          <Link to="/transfer" className="item">
            <i className="fas fa-exchange-alt"></i>
            <span>{langEng ? 'Transfer' : 'Transferencias'}</span>
          </Link>
        </div>

        <div className="transactions">
          <h3>
            {langEng ? 'Transaction history' : 'Historial de transacciones'}
          </h3>

          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onIndexChange={handleIndexChangeTab}
          />

          <SwipeableComponent
            currentItem={activeSwipeView}
            components={components}
            onIndexChange={handleIndexChange}
          />
        </div>
      </div>
    </Layout>
  );
}

export default App;
