import Layout from '@components/layout';
import './style.css';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  //@ts-ignore
  const langEng = i18n.language === 'en' ?? false;

  const [showlearmore, setShowLearnMore] = useState<boolean>(false);

  return (
    <Layout icon={false} showBottomNav={true} showGoBack={false}>
      <div id="airdrop">
        <div className="balance-container">
          <div className="balance-section">
            <div
              style={{
                alignItems: 'center',
              }}
            >
              <h2>{langEng ? 'Total Points' : 'Puntos totales'}</h2>
              <div className="balance-amount">
                {/* &nbsp;{(points || 0).toFixed(2)} */}0
              </div>
            </div>
          </div>
        </div>
        <div>
          <img style={{ width: '80%' }} src={require('./assets/image.png')} />
        </div>
        <div
          className="learnmore"
          onClick={() => setShowLearnMore(!showlearmore)}
        >
          <h4>{langEng ? 'Learn more' : 'Leer más'}</h4>
        </div>
        {showlearmore && (
          <div className="learnmorecontent">
            <p>
              The innovation zone is designed for the integration of new
              cryptographic developments that seek to promote their technology
              and vision through our platform. At Mining Options we are
              responsible for thoroughly reviewing each of the foundations that
              comprise these projects, prioritizing the interests and benefits
              that they can bring to our entire community.
            </p>
            <p>
              <b>
                Remember to stay tuned to our official media to be aware of the
                next launch
              </b>
            </p>
          </div>
        )}
        {/* <div className="info">
            <div>
              <div>
                <span>Next Tickets</span>
                <br />
                <span>{timeRemaining}</span>
              </div>
            </div>
            <div>
              <div>
                <span>Ticket Amount</span>
                <br />
                <span>
                  <i className="fas fa-ticket-alt"></i>{' '}
                  {availableTickets?.available}
                </span>
              </div>
            </div>
          </div> */}
        <br /> <br />
        {/* <div className="content-poster">
            <img src={require('./assets/pngpi.png')} alt="" />

            {availableTickets && availableTickets.available > 0 && (
              <Buttom
                size="lg"
                label="Play"
                onClick={() => {
                  setShowGame(true);
                  usedTicket();
                }}
              />
            )}
          </div> */}
        <br />
        <br />
        {/* <div className="info">
            <div className="item-info" onClick={() => navigate('/frenspoints')}>
              <i className="fas fa-user-plus"></i>
              <span>
                Invite friends and get extra tickets and 5% of the score
                obtained
              </span>
            </div>
            <div className="item-info">
              <i className="fas fa-search"></i>
              <span>Learn more about the $DMO token and its function</span>
            </div>
          </div> */}
      </div>
    </Layout>
  );
}

export default App;
