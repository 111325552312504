import React, { useState } from 'react';
import BottomNav from '@components/bottomNav';
import './style.css';
import LeftMenu from '@components/leftMenu';
import { useSelector } from 'react-redux';
import { AppStateType, store } from '@redux/store';
import { openMenu } from '@redux/slices/mobileMenu/types';
import { ReactComponent as NotificationIcon } from '@assets/icons/notification.svg';
import { useNavigate } from 'react-router-dom';
import ChangeLang from '@components/changeLang';

type Props = {
  title?: string;
  children: React.ReactNode;
  icon?: boolean;
  showBottomNav?: boolean;
  showNavMobile?: boolean;
  showNotification?: boolean;
  showGoBack?: boolean;
  showWallet?: boolean;
};

const Layout: React.FC<Props> = ({
  title = '',
  children,
  icon = true,
  showBottomNav = false,
  showNavMobile = true,
  showNotification = true,
  showGoBack = true,
  showWallet = false,
}) => {
  const navigate = useNavigate();

  const { openMenu: isOpenMenu } = useSelector(
    (state: AppStateType) => state.openMenuNav,
  );

  const [language, setLanguage] = useState('en');

  const switchLanguage = () => {
    const newLanguage = language === 'en' ? 'es' : 'en';
    setLanguage(newLanguage);

    // Cambiar el atributo del idioma en el documento HTML
    document.documentElement.lang = newLanguage;
  };

  const openNav = () => {
    store.dispatch(openMenu(!isOpenMenu));
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="App">
      <div className="child-component">
        {title !== '' && (
          <div id="layout-title">
            {icon && (
              <img
                src={
                  'https://seeklogo.com/images/T/tether-usdt-logo-FA55C7F397-seeklogo.com.png'
                }
                alt=""
              />
            )}
            <h3>{title}</h3>
          </div>
        )}

        <div className="bartop">
          {showNavMobile && (
            <div className="icon-bars-menu" onClick={openNav}>
              <i className="fas fa-bars"></i>
            </div>
          )}

          {showGoBack && (
            <div className="icon-back-menu" onClick={goBack}>
              <i className="fas fa-chevron-left"></i>
            </div>
          )}

          {showNotification && <ChangeLang />}

          {showWallet && (
            <div className="wallet">
              <span>
                <i className="fas fa-wallet"></i> &nbsp; ...XGV2JS
              </span>
            </div>
          )}
        </div>

        {children}
      </div>

      {showBottomNav && <BottomNav />}

      <div
        className={`mobile-nav-menu-content ${isOpenMenu ? 'active' : ''}`}
        onClick={openNav}
      >
        <div className="mobile-nav-menu">
          <LeftMenu />
        </div>
      </div>
    </div>
  );
};

export default Layout;
