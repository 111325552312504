import { useEffect, useState } from 'react';
import { AuthProps, TelegramUser } from 'types/auth';
import './style.css';
declare global {
  interface Window {
    onTelegramAuth: (user: TelegramUser) => void;
  }
}

type Props = {
  authMethod: (data: AuthProps) => void;
};
const TelegramLogin = ({ authMethod }: Props) => {
  useEffect(() => {
    window.onTelegramAuth = function (user: TelegramUser) {
      const data = {
        provider: 'telegram',
        provider_user_id: user.id,
        name: user.first_name,
        provider_data: user,
        photoProfile: user.photo_url,
      };
      authMethod(data);
    };

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://telegram.org/js/telegram-widget.js?22';
    script.setAttribute('data-telegram-login', 'miningoptionsbot');
    script.setAttribute('data-size', 'large');
    script.setAttribute('data-onauth', 'onTelegramAuth(user)');
    script.setAttribute('data-request-access', 'write');
    document.getElementById('telegram-login')?.appendChild(script);

    return () => {
      document.getElementById('telegram-login')?.removeChild(script);
    };
  }, [authMethod]);

  const handleClick = () => {
    const telegramLoginButton = document.querySelector(
      '#telegram-login iframe',
    );
    if (telegramLoginButton) {
      (telegramLoginButton as HTMLIFrameElement).click();
    }
  };

  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      console.log('Ready Telegram');
      setVisible(true);
    }, 2000);
  }, []);

  return (
    <div
      onClick={handleClick}
      className="btn-provider telegram-login content-telegram"
      style={{ background: '#282c34' }}
    >
      <img
        src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/1200px-Telegram_logo.svg.png"
        alt="google"
      />
      <span>Continue with Telegram</span>
      <div
        id="telegram-login"
        style={{ opacity: visible ? 0 : 1, zIndex: visible ? 9999 : -999 }}
      />
    </div>
  );
};

export default TelegramLogin;
