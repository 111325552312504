import { useState } from 'react';
import TelegramLogin from '@components/auth/telegram';
import './style.css';
import LogoWhite from '@assets/logos/logo.png';
import { authLogin } from 'hooks/useAuth';
import { AuthProps } from 'types/auth';
import GoogleBtnAuth from '@components/auth/google';
import AppleBtnAuth from '@components/auth/apple';
import { useToast } from '@context/ToastContext';
import { Link } from 'react-router-dom';

const LoginForm = () => {
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const authProiver = (data: AuthProps) => {
    setLoading(true);
    /* const data = {
      provider: 'telegram',
      provider_user_id: 1549073170,
      name: 'Carlos Cardenas',
      provider_data: {
        id: 1549073170,
        first_name: 'Carlos',
        last_name: 'Cardenas',
        auth_date: 1723049786,
        hash: 'ebe7680e81dc10d0021927d2f20bcf269fef94200c2ece40cb76849b98efe91b',
      },
    };*/
    authLogin(data)
      .then((response) => {
        setLoading(false);
        window.location.href = '/';
      })
      .catch((error: any) => {
        setLoading(false);
        alert('A ocurrido un error');
        console.log('error', error);
      });
  };
  return (
    <div className="login-container">
      {loading && <div className="loadingAuth">laoding</div>}

      <div className="login-left">
        <div className="login-content">
          <img src={LogoWhite} alt="Logo" className="logo-auth" />
          <h1>Welcome to Mining Options</h1>
          <form>
            {/** <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            <div className="form-options">
              <a href="#forgot">Forgot Password?</a>
            </div>
            <button
              type="button"
              className="login-button"
              onClick={() => {
                const data = {
                  provider: 'email',
                  provider_user_id: 1549073170,
                  name: email,
                  email: email,
                  password: password,
                };

                var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

                if (email === '') {
                  showToast('Email field is required.', 'error');
                  setLoading(false);
                  return false;
                } else if (!emailRegex.test(email)) {
                  showToast('Please enter a valid email.', 'error');
                  setLoading(false);
                  return false;
                }

                if (password === '') {
                  showToast('The password field is required.', 'error');
                  setLoading(false);
                  return false;
                }

                authProiver(data);
              }}
            >
              Log in
            </button>
            <br />*/}
          </form>

          <p>Sing in or create account</p>
          <br />

          <div className="social-login">
            {/*<button className="btn-provider google-login" onClick={authProiver}>
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/82/Telegram_logo.svg/2048px-Telegram_logo.svg.png"
                alt="google"
              />
              Telegram
            </button>*/}

            <GoogleBtnAuth authMethod={authProiver} />
            <AppleBtnAuth authMethod={authProiver} />
            <TelegramLogin authMethod={authProiver} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
