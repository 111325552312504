import Layout from '@components/layout';
import './style.css';
import { useEffect, useRef, useState } from 'react';
import Buttom from '@components/buttons';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  //@ts-ignore
  const langEng = i18n.language === 'en' ?? false;

  const [openIndex, setOpenIndex] = useState<number | null>(null);
  const [title, setTitle] = useState<string>(
    langEng ? 'Select a theme:' : 'Seleccione un Tema',
  );
  const [option, setOption] = useState<
    | 'tasks'
    | 'stake'
    | 'deposit'
    | 'withdraw'
    | 'referrals'
    | 'airdrop'
    | 'account'
    | 'other'
    | 'none'
  >('none');
  const [currentOption, setCurrentOption] = useState<string>(option);

  const handleToggle = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  if (option !== currentOption) {
    setOpenIndex(null);
    setCurrentOption(option);
  }

  const faqData: {
    tasks: { question: string; answer: string }[];
    stake: { question: string; answer: string }[];
    deposit: { question: string; answer: string }[];
    withdraw: { question: string; answer: string }[];
    referrals: { question: string; answer: string }[];
    airdrop: { question: string; answer: string }[];
    account: { question: string; answer: string }[];
    other: { question: string; answer: string }[];
  } = {
    tasks: [
      {
        question: langEng
          ? "Why can't I access the tasks?"
          : '¿Por qué no puedo acceder a las tareas?',
        answer: langEng
          ? 'Only accounts that have a linked Telegram profile can access the subscription tasks.'
          : 'Solo las cuentas que tengan un perfil de Telegram vinculado pueden acceder a las tareas de suscripción.',
      },
      {
        question: langEng
          ? 'Why is my task not verified?'
          : '¿Por qué mi tarea no está verificada?',
        answer: langEng
          ? 'Subscription tasks have a review time of up to 48 hours.'
          : 'Las tareas de suscripción tienen un tiempo de revisión de hasta 48 horas.',
      },
      {
        question: langEng
          ? 'How long does the increase in referral rewards last?'
          : '¿Cuánto tiempo dura el aumento de recompensas por referidos?',
        answer: langEng
          ? 'It lasts 30 days, you will receive a notification indicating when it is about to end.'
          : 'Dura 30 días, recibirás una notificación indicando cuándo está por finalizar.',
      },
      {
        question: langEng
          ? 'Where can I track my rewards?'
          : '¿Dónde puedo rastrear mis recompensas?',
        answer: langEng
          ? 'Once the increase is activated, it is applied automatically and you can track it in the “Referrals” section.'
          : 'Una vez activado el aumento, se aplica automáticamente y puedes rastrearlo en la sección “Referidos”.',
      },
      {
        question: langEng
          ? 'I sent a link and have not received a response'
          : 'Envié un enlace y no he recibido respuesta',
        answer: langEng
          ? 'The link review time depends on the flow of users and there is no time limit, they will be approved in order of arrival.'
          : 'El tiempo de revisión del enlace depende del flujo de usuarios y no hay un límite de tiempo, serán aprobados en orden de llegada.',
      },
      {
        question: langEng
          ? 'Where can I see the increase in my stake rewards?'
          : '¿Dónde puedo ver el aumento en mis recompensas de stake?',
        answer: langEng
          ? 'You will be able to see the increase in your benefits reflected in the history of completed stakes from “Stake Summary.”'
          : 'Podrás ver el aumento de tus beneficios reflejado en el historial de stakes completados desde “Resumen de Stake”.',
      },
    ],
    stake: [
      {
        question: langEng
          ? 'Can I cancel my stake once it has started?'
          : '¿Puedo cancelar mi stake una vez iniciado?',
        answer: langEng
          ? "Yes, you can do so from the 'Stake Summary' and at any time you will see the refund process in the deposit section in 'Wallet'."
          : "Sí, puedes hacerlo desde el 'Resumen de Stake' y en cualquier momento verás el proceso de reembolso en la sección de depósitos en 'Billetera'.",
      },
      {
        question: langEng
          ? 'How can I increase my trading limit?'
          : '¿Cómo puedo aumentar mi límite de trading?',
        answer: langEng
          ? 'The trading limit has no pre-setting, it is set based on the first amount entered into the selected contract and resets after 30 days.'
          : 'El límite de trading no tiene una configuración previa, se establece según el primer monto ingresado en el contrato seleccionado y se restablece después de 30 días.',
      },
      {
        question: langEng
          ? 'Do contracts longer than 30 days have compound interest?'
          : '¿Los contratos mayores a 30 días tienen interés compuesto?',
        answer: langEng
          ? '90 and 180 contracts reinvest every 30 days the entire amount obtained until their completion.'
          : 'Los contratos de 90 y 180 días reinvierten cada 30 días el monto total obtenido hasta su finalización.',
      },
      {
        question: langEng
          ? 'Does the trading limit reset if I cancel a stake?'
          : '¿Se restablece el límite de trading si cancelo un stake?',
        answer: langEng
          ? 'If you have not received rewards on that specific contract, you can cancel your stake and you will not have any operation limit.'
          : 'Si no has recibido recompensas en ese contrato específico, puedes cancelar tu stake y no tendrás ningún límite de operación.',
      },
      {
        question: langEng
          ? 'Is the trading limit set on all contracts at once?'
          : '¿El límite de trading se aplica a todos los contratos a la vez?',
        answer: langEng
          ? 'The operation limit is independent of each contract, 30, 90, and 180 contracts have a 30-day trading limit just like the others.'
          : 'El límite de operación es independiente para cada contrato, los contratos de 30, 90 y 180 días tienen un límite de 30 días al igual que los demás.',
      },
      {
        question: langEng
          ? 'Can I use all contracts at once?'
          : '¿Puedo usar todos los contratos a la vez?',
        answer: langEng
          ? 'Yes, you can participate in all contracts simultaneously.'
          : 'Sí, puedes participar en todos los contratos simultáneamente.',
      },
    ],
    deposit: [
      {
        question: langEng
          ? 'How long does it take for my deposit to be reflected?'
          : '¿Cuánto tarda en reflejarse mi depósito?',
        answer: langEng
          ? "USDT deposits work within Binance's BEP 20 network and are reflected in your balance according to the network delay, which is only a few minutes."
          : 'Los depósitos de USDT funcionan dentro de la red BEP 20 de Binance y se reflejan en tu saldo según el retraso de la red, que es de solo unos minutos.',
      },
      {
        question: langEng
          ? 'Can I deposit another token into the wallet?'
          : '¿Puedo depositar otro token en la billetera?',
        answer: langEng
          ? "Contact support directly by pressing 'Other' to help with your case."
          : "Contacta con soporte directamente presionando 'Otro' para ayudarte con tu caso.",
      },
      {
        question: langEng
          ? 'Can deposit wallets change?'
          : '¿Pueden cambiar las billeteras de depósito?',
        answer: langEng
          ? 'Wallets are unique for each user and will not change without prior notice. It is also recommended to always check.'
          : 'Las billeteras son únicas para cada usuario y no cambiarán sin previo aviso. También se recomienda verificar siempre.',
      },
    ],
    withdraw: [
      {
        question: langEng
          ? 'How long does it take to process my withdrawal?'
          : '¿Cuánto tiempo tarda en procesarse mi retiro?',
        answer: langEng
          ? 'Withdrawals have a semi-automatic verification process and are sent within 8 hours.'
          : 'Los retiros tienen un proceso de verificación semiautomático y se envían en un plazo de 8 horas.',
      },
      {
        question: langEng
          ? 'Do I have a maximum withdrawal limit per day?'
          : '¿Tengo un límite máximo de retiro por día?',
        answer: langEng
          ? 'There is no restriction on withdrawing your funds, you can do so at any time.'
          : 'No hay ninguna restricción para retirar tus fondos, puedes hacerlo en cualquier momento.',
      },
      {
        question: langEng
          ? 'Where do I track my withdrawal?'
          : '¿Dónde puedo rastrear mi retiro?',
        answer: langEng
          ? "You will see the status of 'Processing' when you request it and 'Completed' when it is in your wallet."
          : "Verás el estado 'Procesando' cuando lo solicites y 'Completado' cuando esté en tu billetera.",
      },
      {
        question: langEng
          ? 'My withdrawal exceeded the maximum waiting time'
          : 'Mi retiro superó el tiempo máximo de espera',
        answer: langEng
          ? "Contact support directly by clicking 'Other' and your case will be attended to with priority."
          : "Contacta directamente con soporte haciendo clic en 'Otro' y tu caso será atendido con prioridad.",
      },
    ],

    referrals: [
      {
        question: langEng
          ? "How do I get my referrals' rewards?"
          : '¿Cómo obtengo las recompensas de mis referidos?',
        answer: langEng
          ? "In the 'Wallet' section, press 'Transfer', select 'Referrals' as the source point and send directly to 'Stake' or 'Wallet'."
          : "En la sección 'Billetera', presiona 'Transferir', selecciona 'Referidos' como el punto de origen y envía directamente a 'Stake' o 'Billetera'.",
      },
      {
        question: langEng
          ? "How long does it take for my referrals' staking reward to reflect?"
          : '¿Cuánto tiempo tarda en reflejarse la recompensa de staking de mis referidos?',
        answer: langEng
          ? 'Your referral will see their reward immediately after completing a Stake.'
          : 'Tu referido verá su recompensa inmediatamente después de completar un Stake.',
      },
      {
        question: langEng
          ? 'How many referral levels are there?'
          : '¿Cuántos niveles de referidos hay?',
        answer: langEng
          ? 'There is only one referral level, you can only get rewards from your direct referrals.'
          : 'Solo hay un nivel de referidos, solo puedes obtener recompensas de tus referidos directos.',
      },
    ],

    airdrop: [
      {
        question: langEng
          ? 'How many tickets can I get to accumulate points?'
          : '¿Cuántos boletos puedo obtener para acumular puntos?',
        answer: langEng
          ? 'You can get 3 tickets immediately when you create your account and you can receive one free ticket every day for 30 days.'
          : 'Puedes obtener 3 boletos inmediatamente al crear tu cuenta y recibirás un boleto gratis cada día durante 30 días.',
      },
      {
        question: langEng
          ? 'How to get more points?'
          : '¿Cómo obtener más puntos?',
        answer: langEng
          ? "You can get an extra ticket with each referral as well as 5% of all the points they earn, and you can claim them from the 'Airdrop' section by clicking on 'Invite friends…'."
          : "Puedes obtener un boleto extra con cada referido, además del 5% de todos los puntos que ellos ganen. Puedes reclamarlos desde la sección 'Airdrop' haciendo clic en 'Invitar amigos…'.",
      },
      {
        question: langEng
          ? 'When can I redeem my tokens?'
          : '¿Cuándo puedo canjear mis tokens?',
        answer: langEng
          ? 'You can see detailed information about $DMO and its roadmap from our web portal.'
          : 'Puedes ver información detallada sobre $DMO y su hoja de ruta en nuestro portal web.',
      },
    ],

    account: [
      {
        question: langEng
          ? 'How can I link my email address?'
          : '¿Cómo puedo vincular mi dirección de correo electrónico?',
        answer: langEng
          ? "If you registered with Telegram and want to link a Google or Apple email, you must log in from your browser by clicking 'Launch Desktop' then 'continue with Telegram'. Once you log in, press 'Linking accounts' and select the email of your choice."
          : "Si te registraste con Telegram y deseas vincular un correo de Google o Apple, debes iniciar sesión desde tu navegador haciendo clic en 'Launch Desktop' y luego en 'Continuar con Telegram'. Una vez dentro, presiona 'Vincular cuentas' y selecciona el correo de tu elección.",
      },
      {
        question: langEng
          ? 'How can I link my Telegram account?'
          : '¿Cómo puedo vincular mi cuenta de Telegram?',
        answer: langEng
          ? "Log in from your browser and go to 'Linking accounts'. There you can select 'Continue with Telegram' and if you do not have a previous profile linked to your phone number, you can do the linking."
          : "Inicia sesión desde tu navegador y ve a 'Vincular cuentas'. Allí puedes seleccionar 'Continuar con Telegram' y, si no tienes un perfil previo vinculado a tu número de teléfono, podrás hacer la vinculación.",
      },
    ],

    other: [],
  };

  const FAQItem = ({
    question,
    answer,
    isOpen,
    onClick,
  }: {
    question: string;
    answer: string;
    isOpen: boolean;
    onClick: () => void;
  }) => {
    const [maxHeight, setMaxHeight] = useState<string | number>(0);
    const answerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (isOpen && answerRef.current) {
        setMaxHeight(answerRef.current.scrollHeight);
      } else {
        setMaxHeight(0);
      }
    }, [isOpen]);

    return (
      <div className="faq-item">
        <button className="faq-question" onClick={onClick}>
          {question}
          <span className={`arrow ${isOpen ? 'open' : ''}`}>&#9662;</span>
        </button>
        <div
          className="faq-answer-container"
          style={{
            maxHeight: isOpen ? maxHeight : 0,
            overflow: 'hidden',
            transition: 'max-height 0.3s ease',
          }}
          ref={answerRef}
        >
          <div className="faq-answer">{answer}</div>
        </div>
      </div>
    );
  };
  return (
    <Layout icon={false} showBottomNav={false} showNavMobile={false}>
      <div id="support">
        <div className="balance-container">
          <div className="balance-section">
            <span className="balance-label">{title}</span>
          </div>

          {option === 'none' && (
            <div id="support-items">
              <div
                className="item"
                onClick={() => [setOption('tasks'), setTitle('tasks')]}
              >
                <span>{langEng ? 'Tasks' : 'Tareas'}</span>
              </div>
              <div
                className="item"
                onClick={() => [setOption('stake'), setTitle('stake')]}
              >
                <span>Stake</span>
              </div>
              <div
                className="item"
                onClick={() => [setOption('deposit'), setTitle('deposit')]}
              >
                <span>{langEng ? 'Deposit' : 'Depositos'}</span>
              </div>
              <div
                className="item"
                onClick={() => [setOption('withdraw'), setTitle('withdraw')]}
              >
                <span>{langEng ? 'Withdraw' : 'Retiros'}</span>
              </div>
              <div
                className="item"
                onClick={() => [setOption('referrals'), setTitle('referrals')]}
              >
                <span>{langEng ? 'Referrals' : 'Referidos'}</span>
              </div>
              <div className="item" onClick={() => alert('Coming soon')}>
                <span>Airdrop</span>
              </div>
              <div
                className="item"
                onClick={() => [setOption('account'), setTitle('account')]}
              >
                <span>{langEng ? 'Account' : 'Cuenta'}</span>
              </div>
              <div className="item">
                <a href="mailto:support@miningoptions.net" target="_blank">
                  <span>{langEng ? 'Other' : 'Otros'}</span>
                </a>
              </div>
            </div>
          )}

          <br />
          <br />
          {option !== 'none' && (
            <>
              {faqData[option].map((faq, index) => (
                <FAQItem
                  key={index}
                  question={faq.question}
                  answer={faq.answer}
                  isOpen={openIndex === index}
                  onClick={() => handleToggle(index)}
                />
              ))}

              <center style={{ width: '40%', margin: '0 auto' }}>
                <Buttom
                  label="Back"
                  size="lg"
                  onClick={() => [
                    setOption('none'),
                    setTitle('Select a theme:'),
                  ]}
                />
              </center>
            </>
          )}
        </div>
        <br />
      </div>
    </Layout>
  );
}

export default App;
