import { useState } from 'react';
import Layout from '@components/layout';
import Header from '@components/header';

import Task from '@components/task';
import ExtraTask from '@components/extraTask';
import SwipeableComponent from '@components/swipeable';

import './style.css';
import Tabs from '@components/tabs';
import { useTranslation } from 'react-i18next';
function App() {
  const { i18n } = useTranslation();
  //@ts-ignore
  const langEng = i18n.language === 'en' ?? false;
  const components = [<Task />, <ExtraTask />];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [activeTab, setActiveTab] = useState<number>(0);

  const tabs = [
    {
      title: langEng ? 'Tasks' : 'Tareas',
    },
    {
      title: langEng ? 'Extra Task' : 'Tareas Extras',
    },
  ];

  const handleIndexChangeTab = (index: number) => {
    setCurrentIndex(index);
  };

  const handleIndexChange = (index: number) => {
    setActiveTab(index);
    setCurrentIndex(index);
  };

  return (
    <Layout showBottomNav={true} showGoBack={false}>
      <Header />

      <div id="content-home">
        <div>
          <Tabs
            tabs={tabs}
            activeTab={activeTab}
            onIndexChange={handleIndexChangeTab}
          />
        </div>

        <SwipeableComponent
          currentItem={currentIndex}
          components={components}
          onIndexChange={handleIndexChange}
        />
      </div>
    </Layout>
  );
}

export default App;
