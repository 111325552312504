import LogoWhite from '@assets/logos/logo.png';
import './style.css';
import Buttom from '@components/buttons';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const Index = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const [isOpenMenu, setisOpenMenu] = useState(false);
  const openNav = () => {
    setisOpenMenu(!isOpenMenu);
  };

  const BinanceIcon = () => {
    return (
      <img src={require('../assets/images/bnb.png')} style={{ width: 35 }} />
    );
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const referCode = queryParams.get('refercode');

    if (referCode) {
      localStorage.setItem('refercode', referCode);
      console.log('Refercode saved:', referCode);
    } else {
      localStorage.removeItem('refercode');
    }
  }, [location]);

  const employees = [
    {
      name: 'SAMUEL PRATT',
      role: 'DEVELOPER',
      email: 'samuelprattg@gmail.com',
    },
    {
      name: 'ALEX SPRUILL',
      role: 'WEB DEVELOPER',
      email: 'alexspruill@hotmail.com',
    },
    {
      name: 'MELISSA SMITH',
      role: 'GRAPHIC DESIGNER',
      email: 'melissasmithwv@outlook.com',
    },
    {
      name: 'CHRIS MOLENAAR',
      role: 'GRAPHIC DESIGNER',
      email: 'chrismolenaar082@gmail.com',
    },
    {
      name: 'ZARI MATTS',
      role: 'MARKETING CONSULTANT',
      email: 'zarimatts@hotmail.com',
    },
    {
      name: 'NAHOMI FERY',
      role: 'RISK MANAGER',
      email: 'nahomifery@proton.me',
    },
    { name: 'IVAN BOLD', role: 'DATA ANALYST', email: 'ivanbolds@proton.me' },
    {
      name: 'HENRY EASTWOOD',
      role: 'BITCOIN MINER',
      email: 'henrryeastwood@gmail.com',
    },
    {
      name: 'ROBERT GENES',
      role: 'CRYPTOCURRENCY TRADER',
      email: 'robertgeness@hotmail.com',
    },
    {
      name: 'JAMES MOORE',
      role: 'FINANCIAL ANALYST',
      email: 'jamesmooretr3@hotmail.com',
    },
    {
      name: 'ZAC DRISKELL',
      role: 'CUSTOMER SUPPORT',
      email: 'zac47091@gmail.com',
    },
    {
      name: 'FELIX RUGGERI',
      role: 'CUSTOMER SUPPORT',
      email: 'felixruggeri1@gmail.com',
    },
    {
      name: 'DAVID KIM',
      role: 'MARKET STRATEGIST',
      email: 'davidkimbellb@proton.me',
    },
    {
      name: 'MATTHEW CONNOR',
      role: 'SENIOR DATA ENGINEER',
      email: 'mattew.connor@yahoo.com',
    },
  ];

  useEffect(() => {
    // Agregar clase al body al entrar en el componente
    document.body.classList.add('overflow-scroll');

    // Limpiar la clase al salir del componente
    return () => {
      document.body.classList.remove('overflow-scroll');
    };
  }, []);

  return (
    <div className="launch-container">
      <nav id="main-nav">
        <div className="content-nav">
          <div>
            <Link to="/">
              <img src={LogoWhite} alt="MiningOptions" className="logoLaunch" />
            </Link>
          </div>
          <div>
            <ul>
              <li>
                <Link to="/dmo-token">DMO Token</Link>
              </li>
              <li>
                <a
                  href="https://miningoptions.net/MININGOPTIONS.pdf"
                  target="_blank"
                  translate="no"
                >
                  White Paper
                </a>
              </li>
              <li>
                <Link to="/#faq">FAQ</Link>
              </li>
              <li className="activenav">
                <a href="#">Team</a>
              </li>
              <li>
                <Link to="/contact-us">Contact us</Link>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <div className="social-network">
            <a href="https://t.me/mining_options" target="_blank">
              <img
                src={require('../assets/images/telegram2.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://x.com/mining_options?s=11&t=P5Q0Gh941v-xBEugpAY3lg"
              target="_blank"
            >
              <img
                src={require('../assets/images/twitter-alt-circle.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://www.instagram.com/mining_options?igsh=MzRlODBiNWFlZA=="
              target="_blank"
            >
              <img
                src={require('../assets/images/instagram.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://youtube.com/@mining_options?si=y6RqQmusDDybkmfc"
              target="_blank"
            >
              <img
                src={require('../assets/images/youtube.png')}
                style={{ width: 33 }}
              />
            </a>
            <a
              href="https://www.binance.com/en/square/profile/mining_options"
              target="_blank"
            >
              <BinanceIcon />
            </a>
          </div>
          <div
            className="social-network"
            style={{
              width: 120,
              margin: '0 auto',
            }}
          ></div>
        </div>

        <div className="icon-bars-menu-launch" onClick={openNav}>
          <i className="fas fa-bars"></i>
        </div>
      </nav>
      <div
        className={`mobile-nav-menu-content ${isOpenMenu ? 'active' : ''}`}
        onClick={openNav}
      >
        <div className="mobile-nav-menu">
          <div className="items-menu">
            <div className="section-item-menu">
              <ul>
                <li className={`left-menu-item`}>
                  <Link to="/dmo-token">DMO Token</Link>
                </li>
                <li className={`left-menu-item`}>
                  <a
                    href="https://miningoptions.net/MININGOPTIONS.pdf"
                    target="_blank"
                    translate="no"
                  >
                    White Paper
                  </a>
                </li>

                <li className={`left-menu-item`}>
                  <a href="#faq">FAQ</a>
                </li>
                <li className={`left-menu-item`}>
                  <Link to="/team">Team</Link>
                </li>
                <li className={`left-menu-item`}>
                  <Link to="/contact-us">Contact us</Link>
                </li>
              </ul>
              <div
                style={{
                  width: 120,
                  margin: '0 auto',
                }}
              >
                <Buttom
                  size="lg"
                  label="Start Now"
                  onClick={() => {
                    navigate('/sing-in');
                  }}
                />
              </div>

              <div className="social-network" style={{ margin: '30px auto' }}>
                <a href="https://t.me/mining_options" target="_blank">
                  <img
                    src={require('../assets/images/telegram2.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://x.com/mining_options?s=11&t=P5Q0Gh941v-xBEugpAY3lg"
                  target="_blank"
                >
                  <img
                    src={require('../assets/images/twitter-alt-circle.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://www.instagram.com/mining_options?igsh=MzRlODBiNWFlZA=="
                  target="_blank"
                >
                  <img
                    src={require('../assets/images/instagram.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://youtube.com/@mining_options?si=y6RqQmusDDybkmfc"
                  target="_blank"
                >
                  <img
                    src={require('../assets/images/youtube.png')}
                    style={{ width: 33 }}
                  />
                </a>
                <a
                  href="https://www.binance.com/en/square/profile/mining_options"
                  target="_blank"
                >
                  <BinanceIcon />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br />
      <section id="team-content" className="content padding-inline">
        <h1 className="text-center">OUR TEAM</h1>
        <br />

        <div id="our-team">
          <div className="card-home" id="team-lead">
            <div id="photo-lead">
              <img src={require('./assets/lead.png')} />
            </div>
            <h2>Richard Costner Koch</h2>
            <p>(Chief Executive Officer)</p>
            <p>
              Richard has extensive experience in managing and growing
              innovative companies in the cryptographic field, standing out in
              the most specialized areas of traditional mining and web 3
              development.
            </p>
            <a
              href="https://www.f6s.com/member/richard-costner-koch?event=focus-thumbnail#about"
              target="_blank"
            >
              <div className="icontelegram">
                <img
                  src={require('./assets/iconf6s.png')}
                  width={50}
                  height={50}
                />
              </div>
            </a>
          </div>
          <div id="team-chief">
            <br />
            <div className="flex-row gap5">
              <div className="card-home">
                <h2>Sussan L Pritzker</h2>
                <p>(Chief Financial Officer)</p>
                <a href="Https://t.me/sussanpritzker" target="_blank">
                  <div className="icontelegram">
                    <i className="fab fa-telegram-plane"></i>
                  </div>
                </a>
              </div>
              <div className="card-home">
                <h2>Jeff F Weston</h2>
                <p>(Chief Marketing Officer)</p>
                <a href="Https://t.me/jefffweston" target="_blank">
                  <div className="icontelegram">
                    <i className="fab fa-telegram-plane"></i>
                  </div>
                </a>
              </div>
            </div>
            <br />
            <br />
            <br />
            <br />
            <br />
            <div className="flex-row gap10">
              <div className="card-home">
                <h2>Ryan H Cohen</h2>
                <p>(Chief Operating Officer)</p>
                <a href="https://t.me/ryanhcohen" target="_blank">
                  <div className="icontelegram">
                    <i className="fab fa-telegram-plane"></i>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <section id="employes" className="content padding-inline">
        <div className="card-home">
          <h1 className="text-center">EMPLOYEES LIST</h1>

          <div className="employee-container">
            {employees.map((employee, index) => (
              <div key={index} className="employee-row">
                <span className="employee-name">{employee.name}</span>
                <span className="employee-role">{employee.role}</span>
                <a
                  href={`mailto:${employee.email}`}
                  className="employee-contact"
                >
                  CONTACT
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <section className="content padding-inline">
        <div id="footer-btns">
          <div>
            <Link to="/">Mining Options</Link>

            <Link to="/dmo-token">DMO Token</Link>

            <div>
              <a
                href="https://miningoptions.net/MININGOPTIONS.pdf"
                target="_blank"
              >
                White Paper
              </a>
            </div>

            <Link to="/contact-us">Contact Us</Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
