import { useEffect, useState } from 'react';
import './style.css';
import Buttom from '@components/buttons';
import { useSelector } from 'react-redux';
import { AppStateType, store } from '@redux/store';
import {
  getTasks,
  checkAllTasks,
  verifyTask,
  activateReward,
  getBoost,
} from '@hooks/useVerifyTask';

import TelegramLogin from '@components/auth/telegram';
import { tasks } from './data';
import { AuthProps } from 'types/auth';
import { userData } from '@redux/slices/initLoad/types';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CoheteIcon } from '@assets/icons/lanzamiento-de-cohete-espacial.svg';
import { useTranslation } from 'react-i18next';

function App() {
  const { i18n } = useTranslation();
  //@ts-ignore
  const langEng = i18n.language === 'en' ?? false;
  const { user } = useSelector((state: AppStateType) => state.initLoad);
  const [showTelegramAuth, setShowTelegramAuth] = useState<boolean>(false);
  const [completedTasks, setCompletedTasks] = useState<boolean>(false);
  const [hasBoost, setHasBoost] = useState<any>(false);

  const showPopUp =
    user.provider !== 'telegram' &&
    !user.liking_account &&
    user.liking_account.provider !== 'telegram';

  const navigate = useNavigate();

  useEffect(() => {
    checkAllTasks().then((response: any) => {
      setCompletedTasks(response.completed);
    });

    getBoost().then((data) => {
      setHasBoost(data);
    });
  }, []);

  const activeTaskReward = () => {
    activateReward().then(() => {
      setCompletedTasks(true);
      alert('successful operation');

      getBoost().then((data) => {
        setHasBoost(data);
      });
    });
  };

  function openLinkInNewTab(url: string) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }

  const authTelegram = (data: AuthProps) => {
    const auth_telegram = {
      user_id: `${data.provider_user_id}`,
    };

    store.dispatch(
      userData({
        ...user,
        auth_telegram,
      }),
    );
  };
  const ItemTask = (props: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [label, setLabel] = useState<string>('Start');
    const [start, setStart] = useState<boolean>(false);

    useEffect(() => {
      getTasks(props.task.platform)
        .then((data) => {
          if (data) {
            if (data.status === 'Pending') {
              setLabel(data.status);
              setDisabled(true);
              setLoading(true);
            } else {
              setLabel(data.status);
              setDisabled(true);
              setLoading(false);
            }
          } else {
            setLabel('Start');
          }
        })

        .catch(() => {
          setLabel('Start');
        });
    }, []);

    useEffect(() => {
      const handleVisibilityChange = () => {
        if (document.hidden) {
          console.log(start, 'El usuario ha salido de la pestaña');
          if (start) {
            setLabel('Check');
          }
        } else {
          if (loading) {
            setLabel('Check');
          } else {
            setLoading(false);
          }
        }
      };

      document.addEventListener('visibilitychange', handleVisibilityChange);

      return () => {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange,
        );
      };
    }, [start]);

    const openTask = () => {
      if (label === 'Start') {
        openLinkInNewTab(props.task.link);
        setStart(true);
      } else {
        setLoading(true);

        if (
          user.provider !== 'telegram' &&
          props.task.platform === 'Telegram'
        ) {
          console.log(user.auth_telegram, 'auth_telegram');

          if (!user.auth_telegram) {
            // alert('logeate en telegram');
            //setShowTelegramAuth(true);
            //return false;
          }
        }
        const data = {
          provider: props.task.platform,
          provider_user_id:
            props.task.platform === 'Telegram'
              ? user.provider === 'telegram'
                ? user.provider_user_id
                : user.auth_telegram?.user_id ?? ''
              : user.provider_user_id,
        };
        verifyTask(data).then((response: any) => {
          if (response) {
            const delay =
              props.task.platform === 'Telegram' ? 5000 : 10 * 60 * 1000;

            setTimeout(() => {
              setLabel('Done');
              setDisabled(true);
              setLoading(false);
              setShowTelegramAuth(false);
            }, delay);
          } else {
            setLabel('Start');
            setLoading(false);
          }
        });
      }
    };

    return (
      <div key={props.task.id} className="task-item">
        {props.task.image ? (
          <div className="task-content-image">
            <img
              className="task-image"
              src={require(`./assets/${props.task.image}`)}
            />
          </div>
        ) : (
          <i className={`task-icon ${props.task.icon}`}></i>
        )}

        <span className="task-platform">
          {langEng ? 'Subscribe to' : 'Subscribe to'} {props.task.platform}
        </span>

        <Buttom
          label={label}
          size="xs"
          disabled={disabled}
          loading={loading}
          onClick={() => openTask()}
        />
      </div>
    );
  };
  return (
    <>
      <div className={`task-list ${showPopUp ? 'filter-blur' : ''}`}>
        <div className="task-items">
          {showTelegramAuth && (
            <>
              <TelegramLogin authMethod={authTelegram} />
            </>
          )}
          <p>
            {langEng
              ? 'Get up to a 50% increase in your referral earnings'
              : 'Obtenga hasta un 50% de aumento en sus ganancias por referidos'}
          </p>
          {tasks.map((task) => (
            <ItemTask task={task} />
          ))}
        </div>
        <br />
        <p className="activate-bonus">
          {langEng ? 'Activate Task Reward' : 'Activar recompensa'}
        </p>
        <br />
        <div className="task-footer">
          {completedTasks && hasBoost ? (
            <a
              onClick={() => {
                alert(`you have ${hasBoost > 0 ? hasBoost : 0} days left`);
              }}
              className={`activateReward`}
            >
              <CoheteIcon height={20} />
            </a>
          ) : (
            <div className="btn-boost-stake">
              <Buttom
                label="Start"
                disabled={!completedTasks}
                showLabel={false}
                size="lg"
                icon="fas fa-check"
                onClick={() => activeTaskReward()}
              />
            </div>
          )}
        </div>
      </div>

      {showPopUp && (
        <div className="popup-linking-account">
          <div className="content-popup">
            {langEng
              ? 'Link a Telegram account to access tasks'
              : 'Vincular una cuenta de Telegram para acceder a las tareas'}

            <br />
            <br />
            <Buttom
              label={langEng ? 'Linking Accounts' : 'Vincular cuentas'}
              size="lg"
              icon="fas fa-link"
              onClick={() => navigate('/linking-accounts')}
            />
          </div>
        </div>
      )}
    </>
  );
}

export default App;
