import { useTranslation } from 'react-i18next';

const ChangeLang = () => {
  const { i18n } = useTranslation();

  const toggleLanguage = () => {
    const newLang = i18n.language === 'en' ? 'es' : 'en';
    i18n.changeLanguage(newLang);
  };

  return (
    <button
      onClick={toggleLanguage}
      style={{
        background: 'none',
        border: 'none',
        fontWeight: 'bold',
        fontSize: 15,
      }}
      className="p-2 text-white  rounded-lg hover:bg-gray-600 transition iconNotification"
    >
      {i18n.language.toUpperCase()} / {i18n.language === 'en' ? 'ES' : 'EN'}
    </button>
  );
};

export default ChangeLang;
