import React from 'react';
import './style.css';
import LogoWhite from '@assets/logos/logo.png';
import { AppStateType, store } from '@redux/store';
import { isSingin } from '@redux/slices/initLoad/types';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const Index: React.FC = () => {
  const { i18n } = useTranslation();
  //@ts-ignore
  const langEng = i18n.language === 'en' ?? false;

  const { showlogout } = useSelector((state: AppStateType) => state.initLoad);
  const location = useLocation();
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem('@tokenAuth');
    localStorage.removeItem('@userData');
    store.dispatch(isSingin(false));
    navigate('/');
  };
  return (
    <div className="content-left-menu">
      <img src={LogoWhite} alt="MiningOptions" className="left-menu-logo" />
      <div className="items-menu" style={{ width: '100%' }}>
        <div className="section-item-menu">
          <span>LAUNCHPAD</span>
          <ul>
            <li
              onClick={() => navigate('/')}
              className={`left-menu-item ${
                location.pathname === '/' ||
                location.pathname === '/stake' ||
                location.pathname === '/wallet' ||
                location.pathname === '/frens'
                  ? 'active'
                  : ''
              }`}
            >
              <Link to="/">
                <i className="fas fa-tachometer-alt"></i>
                DASHBOARD
              </Link>
            </li>
            <li
              onClick={() => navigate('/stake-summary')}
              className={`left-menu-item ${
                location.pathname === '/stake-summary' ? 'active' : ''
              }`}
            >
              <Link to="/stake-summary" translate="no">
                <i className="fas fa-file-invoice"></i>
                {langEng ? 'STAKE SUMARY' : 'RESUMEN DE STAKE'}
              </Link>
            </li>
            <li
              onClick={() => navigate('/linking-accounts')}
              className={`left-menu-item ${
                location.pathname === '/linking-accounts' ? 'active' : ''
              }`}
            >
              <Link to="/linking-accounts">
                <i className="fas fa-link"></i>
                {langEng ? 'LINKING ACCOUNTS' : 'VINCULACIÓN DE CUENTAS '}
              </Link>
            </li>

            <li
              onClick={() => navigate('/support')}
              className={`left-menu-item ${
                location.pathname === '/support' ? 'active' : ''
              }`}
            >
              <Link to="/support">
                <i className="fas fa-headset"></i>
                {langEng ? 'SUPPORT' : 'SOPORTE'}
              </Link>
            </li>
            <li
              onClick={() => navigate('/settings')}
              className={`left-menu-item ${
                location.pathname === '/settings' ? 'active' : ''
              }`}
            >
              <Link to="/settings">
                <i className="fas fa-sliders-h"></i>
                {langEng ? 'SETTINGS' : 'AJUSTES'}
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {showlogout && (
        <button type="button" className="logout" onClick={logout}>
          {langEng ? 'LOGOUT' : 'CERRAR SESIÓN'}
          <br />
          <i className="fas fa-sign-out-alt"></i>
        </button>
      )}
    </div>
  );
};

export default Index;
